<template>
  <div class="main">
    <img src="/img/static/logo-landing.svg" />
    <h1>End User License Agreement</h1>
    <h4>Last Updated: March 22st, 2021</h4>
    <p>
      <strong> IMPORTANT:</strong> Please carefully read the terms and
      conditions of the product application set out below prior to download,
      installation, copy or use.
      <strong>
        THROUGH DOWNLOADING, INSTALLING, COPYING OR USING THE SOFTWARE YOU ARE
        EXPRESSING YOUR CONSENT TO THESE TERMS AND CONDITIONS.
      </strong>
    </p>
    <br />
    <h4>Software End-User License Agreement</h4>
    <p>
      Under the terms of this Software End User License Agreement (hereinafter
      referred to as “the Agreement”) executed by and between securCom s.r.o.,
      having its registered office at Suché mýto 1, 811 03 Bratislava, Slovak
      Republic, registered in the Commercial Register administered by Bratislava
      I District Court, Section Sro, Entry No 133542/B, Business Registration
      Number: 50 342 657 or another company from the securCom Group (hereinafter
      referred to as "securCom" or “the Provider”) and you, a physical person or
      legal entity (hereinafter referred to as “You" or "the End User”), You are
      entitled to use the Software defined in Article 1 of this Agreement. The
      Software defined in Article 1 of this Agreement can be stored on a data
      carrier, sent via electronic mail, downloaded from the Internet,
      downloaded from the Provider's servers or obtained from other sources,
      subject to the terms and conditions specified below.
    </p>
    <p>
      <strong
        >THIS IS AN AGREEMENT ON END-USER RIGHTS AND NOT AN AGREEMENT FOR
        SALE.</strong
      >
      The Provider continues to own the copy of the Software and the physical
      media contained in the sales package and any other copies that the End
      User is authorized to make pursuant to this Agreement.
    </p>
    <p>
      By installing, downloading, copying or using the Software, You agree to
      the terms and conditions of this Agreement. If You do not agree to all of
      the terms and conditions of this Agreement, cancel the installation or
      download, or destroy or return the Software, installation media,
      accompanying documentation and sales receipt to securCom or the outlet
      from which You acquired the Software.
    </p>
    <p>
      <strong>
        YOU AGREE THAT YOUR USE OF THE SOFTWARE ACKNOWLEDGES THAT YOU HAVE READ
        THIS AGREEMENT, UNDERSTAND IT AND AGREE TO BE BOUND BY ITS TERMS AND
        CONDITIONS.
      </strong>
    </p>
    <ol>
      <li>
        Software. As used in this Agreement the term "Software" means: (i) the
        <strong>SECURCOM</strong> computer program and all components thereof;
        (ii) all the contents of the disks, CDROMs, DVDs, e-mails and any
        attachments, or other media with which this Agreement is provided,
        including the object code form of the Software supplied on a data
        carrier, via electronic mail or downloaded via the Internet; (iii) any
        related explanatory written materials and any other possible
        documentation related to the Software, above all any description of the
        Software, its specifications, any description of the Software properties
        or operation, any description of the operating environment in which the
        Software is used, instructions for use or installation of the Software
        or any description of how to use the Software (hereinafter referred to
        as “Documentation”); (iv) copies of the Software, patches for possible
        errors in the Software, additions to the Software, extensions to the
        Software, modified versions of the Software and updates of Software
        components, if any, licensed to You by the Provider pursuant to Article
        3 of this Agreement. The Software shall be provided exclusively in the
        form of executable object code.
      </li>
      <li>
        Installation. Software supplied on a data carrier, sent via electronic
        mail, downloaded from the Internet, downloaded from the Provider's
        servers or obtained from other sources requires installation. You must
        install the Software on a correctly configured computer, complying at
        least with requirements set out in the Documentation. The installation
        methodology is described in the Documentation. No computer programs or
        hardware which could have an adverse effect on the Software may be
        installed on the computer on which You install the Software.
      </li>
      <li>
        License. Subject to the condition that You have agreed to the terms of
        this Agreement, You pay the License Fee within the maturity period and
        You comply with all the terms and conditions stipulated herein, the
        Provider shall grant You the following rights ("the License"):
      </li>
      <ol type="a">
        <li>
          Installation and use. You shall have the non-exclusive,
          non-transferable right to install the Software on the hard disk of a
          computer or other permanent medium for data storage, installation and
          storage of the Software in the memory of a computer system and to
          implement, store and display the Software.
        </li>
        <li>
          Stipulation of the number of licenses. The right to use the Software
          shall be bound by the number of End Users. One End User shall be taken
          to refer to the following: (i) installation of the Software on one
          computer system. You must not use the same License at the same time on
          more than one computer.
        </li>
        <li>
          Term of the License. Your right to use obtained version of the
          Software shall be not time-limited.
        </li>
        <li>
          OEM Software. OEM Software shall be limited to the computer You
          obtained it with. It cannot be transferred to a different computer
        </li>
        <li>
          NFR, TRIAL Software. Software classified as "Not-for-resale", NFR or
          TRIAL cannot be assigned for payment and must only be used for
          demonstration or testing the Software's features.
        </li>
        <li>
          Termination of the License. The License shall terminate automatically
          at the end of the period for which granted. If You fail to comply with
          any of the provisions of this Agreement, the Provider shall be
          entitled to withdraw from the Agreement, without prejudice to any
          entitlement or legal remedy open to the Provider in such
          eventualities. In the event of cancellation of the License, You must
          immediately delete, destroy or return at your own cost, the Software
          and all backup copies to SECURCOM or to the outlet from which You
          obtained the Software.
        </li>
      </ol>
      <li>
        Exercising End User rights. You must exercise End User rights in person
        or via your employees. You are only entitled to use the Software to
        safeguard your operations and protect those computer systems for which
        You have obtained a License.
      </li>
      <li>
        Restrictions to rights. You may not copy, distribute, extract components
        or make derivative works of the Software. When using the Software You
        are required to comply with the following restrictions:
      </li>
      <ol type="a">
        <li>
          You may make one copy of the Software on a permanent storage medium as
          an archival back-up copy, provided your archival back-up copy is not
          installed or used on any computer. Any other copies You make of the
          Software shall constitute breach of this Agreement
        </li>
        <li>
          You may not use, modify, translate or reproduce the Software or
          transfer rights to use the Software or copies of the Software in any
          manner other than as provided for in this Agreement.
        </li>
        <li>
          You may not sell, sub-license, lease or rent or borrow the Software or
          use the Software for the provision of commercial services.
        </li>
        <li>
          You may not reverse engineer, reverse compile or disassemble the
          Software or otherwise attempt to discover the source code of the
          Software, except to the extent that this restriction is expressly
          prohibited by law.
        </li>
        <li>
          You agree that You will only use the Software in a manner that
          complies with all applicable laws in the jurisdiction in which You use
          the Software, including, but not 3 | P a g e limited to, applicable
          restrictions concerning copyright and other intellectual property
          rights.
        </li>
      </ol>
      <li>
        Copyright. The Software and all rights, without limitation including
        proprietary rights and intellectual property rights thereto are owned by
        SECURCOM and/or its licensors. They are protected by international
        treaty provisions and by all other applicable national laws of the
        country in which the Software is being used. The structure, organization
        and code of the Software are the valuable trade secrets and confidential
        information of SECURCOM and/or its licensors. You must not copy the
        Software, except as set forth in Article 5(a). Any copies which You are
        permitted to make pursuant to this Agreement must contain the same
        copyright and other proprietary notices that appear on the Software. If
        You reverse engineer, reverse compile, disassemble or otherwise attempt
        to discover the source code of the Software, in breach of the provisions
        of this Agreement, You hereby agree that any information thereby
        obtained shall automatically and irrevocably be deemed to be transferred
        to and owned by the Provider in full, from the moment such information
        comes into being, notwithstanding the Provider's rights in relation to
        breach of this Agreement.
      </li>
      <li>
        Reservation of rights. The Provider hereby reserves all rights to the
        Software, with the exception of rights expressly granted under the terms
        of this Agreement to You as the End User of the Software.
      </li>
      <li>
        Multiple language versions, dual media software, multiple copies. In the
        event that the Software supports multiple platforms or languages, or if
        You receive multiple copies of the Software, You may only use the
        Software for the number of computer systems and for the versions for
        which You obtained a License. You may not sell, rent, lease, sublicense,
        lend or transfer versions or copies of the Software which You do not
        use.
      </li>
      <li>
        Commencement and termination of the Agreement. This Agreement is
        effective from the date You agree to the terms of this Agreement. You
        may terminate this Agreement at any time by permanently uninstalling,
        destroying and returning, at your own costs, the Software, all back-up
        copies and all related materials provided by the Provider or its
        business partners. Irrespective of the manner of termination of this
        Agreement, the provisions of Articles 6, 7, 10, 12, 18 and 20 shall
        continue to apply for an unlimited time
      </li>
      <li>
        END USER DECLARATIONS. AS THE END USER YOU ACKNOWLEDGE THAT THE SOFTWARE
        IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
        AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. NEITHER THE
        PROVIDER, ITS LICENSORS OR AFFILIATES NOR THE COPYRIGHT HOLDERS MAKE ANY
        REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT
        LIMITED TO THE 4 | P a g e WARRANTIES OF MERCHANTABILITY OR FITNESS FOR
        A PARTICULAR PURPOSE OR THAT THE SOFTWARE WILL NOT INFRINGE ANY THIRD
        PARTY PATENTS, COPYRIGHTS, TRADEMARKS OR OTHER RIGHTS. THERE IS NO
        WARRANTY BY THE PROVIDER OR BY ANY OTHER PARTY THAT THE FUNCTIONS
        CONTAINED IN THE SOFTWARE WILL MEET YOUR REQUIREMENTS OR THAT THE
        OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED OR ERROR-FREE. YOU
        ASSUME ALL RESPONSIBILITY AND RISK FOR THE SELECTION OF THE SOFTWARE TO
        ACHIEVE YOUR INTENDED RESULTS AND FOR THE INSTALLATION, USE AND RESULTS
        OBTAINED FROM IT.
      </li>
      <li>
        No other obligations. This Agreement creates no obligations on the part
        of the Provider and its licensors other than as specifically set forth
        herein.
      </li>
      <li>
        LIMITATION OF LIABILITY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
        LAW, IN NO EVENT SHALL THE PROVIDER, ITS EMPLOYEES OR LICENSORS BE
        LIABLE FOR ANY LOST PROFITS, REVENUE, SALES, DATA OR COSTS OF
        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, PROPERTY DAMAGE, PERSONAL
        INJURY, INTERRUPTION OF BUSINESS, LOSS OF BUSINESS INFORMATION OR FOR
        ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, ECONOMIC, COVER, PUNITIVE,
        SPECIAL OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED AND WHETHER ARISING
        UNDER CONTRACT, TORT, NEGLIGENCE OR OTHER THEORY OF LIABILITY, ARISING
        OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE, EVEN IF THE PROVIDER
        OR ITS LICENSORS OR AFFILIATES ARE ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. BECAUSE SOME COUNTRIES AND JURISDICTIONS DO NOT ALLOW THE
        EXCLUSION OF LIABILITY, BUT MAY ALLOW LIABILITY TO BE LIMITED, IN SUCH
        CASES, THE LIABILITY OF THE PROVIDER, ITS EMPLOYEES OR LICENSORS OR
        AFFILIATES SHALL BE LIMITED TO THE SUM THAT YOU PAID FOR THE LICENSE.
      </li>
      <li>
        Nothing contained in this Agreement shall prejudice the statutory rights
        of any party dealing as a consumer if running contrary thereto
      </li>
      <li>
        Technical support. SECURCOM or third parties commissioned by SECURCOM
        shall provide technical support at their own discretion, without any
        guarantees or declarations. The End User shall be required to back up
        all existing data, software and program facilities prior to the
        provision of technical support. SECURCOM and/or third parties
        commissioned by SECURCOM cannot accept liability for damage or loss of
        data, property, software or hardware or loss of profits due to the
        provision of technical support. SECURCOM and/or third parties
        commissioned by SECURCOM reserve the right to decide that resolving the
        problem is beyond the scope of technical support. 5 | P a g e SECURCOM
        reserves the right to refuse, suspend or terminate the provision of
        technical support at its own discretion.
      </li>
      <li>
        Transfer of the License. The Software can be transferred from one
        computer system to another, unless contrary to the terms of the
        Agreement. If not contrary to the terms of the Agreement, the End User
        shall only be entitled to permanently transfer the License and all
        rights ensuing from this Agreement to another End User with the
        Provider's consent, subject to the condition that (i) the original End
        User does not retain any copies of the Software; (ii) the transfer of
        rights must be direct, i.e. from the original End User to the new End
        User; (iii) the new End User must assume all the rights and obligations
        incumbent on the original End User under the terms of this Agreement;
        (iv) the original End User has to provide the new End User with
        documentation enabling verification of the genuineness of the Software
        as specified under Article 16.
      </li>
      <li>
        Verification of the genuineness of the Software. The End User may
        demonstrate entitlement to use the Software in one of the following
        ways: (i) through a license certificate issued by the Provider or a
        third party appointed by the Provider; (ii) through a written license
        agreement, if such an agreement was concluded.
      </li>
      <li>
        Licensing for public authorities and the US Government. The Software
        shall be provided to public authorities including the United States
        Government with the license rights and restrictions described in this
        Agreement
      </li>
      <li>
        Export and re-export control. The Software, the Documentation or
        components thereof, including information about the Software and
        components thereof, shall be subject to import and export controls under
        legal regulations which may be issued by governments responsible for
        issue thereof under applicable law, including US law. Export
        Administration Regulations and end-user, end-use and destination
        restrictions issued by the US Government and other governments. You
        agree to comply strictly with all applicable import and export
        regulations and acknowledge that You have the responsibility to obtain
        all licenses required to export, re-export, transfer or import the
        Software.
      </li>
      <li>
        Notices. All notices and return of the Software and Documentation must
        be delivered to: securCom s r. o., Suché mýto 1, 811 03 Bratislava,
        Slovak Republic.
      </li>
      <li>
        Applicable law. This Agreement shall be governed by and construed in
        accordance with the laws of the Slovak Republic. The End User and the
        Provider hereby agree that the principles of the conflict of laws and
        the United Nations Convention on Contracts for the International Sale of
        Goods shall not apply. You expressly agree that any disputes or 6 | P a
        g e claims ensuing from this Agreement with respect to the Provider or
        any disputes or claims relating to use of the Software shall be settled
        by Bratislava I District Court and You expressly agree to the said court
        exercising jurisdiction.
      </li>
      <li>
        General provisions. Should any of the provisions of this Agreement be
        invalid or unenforceable, this shall not affect the validity of the
        other provisions of the Agreement, which shall remain valid and
        enforceable in accordance with the conditions stipulated therein. This
        Agreement may only be modified in written form, signed by an authorized
        representative of the Provider or a person expressly authorized to act
        in this capacity under the terms of a power of attorney.
      </li>
      <p>
        <strong>
          This is the entire Agreement between the Provider and You relating to
          the Software and it supersedes any prior representations, discussions,
          undertakings, communications or advertising relating to the Software.
        </strong>
      </p>
    </ol>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {};
</script>

<style scoped>
a {
  color: #409875;
}

p {
  line-height: 1.4rem;
}

li {
  margin-top: 15px;
}

.main {
  height: 91%;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.noB {
  list-style: none;
  margin-left: -20px;
}

img {
  width: 100%;
  max-width: 230px;
  margin-left: -5px;
}
</style>